<template>
  <div class="vs-col w-full">
    <vs-input
      v-validate="'required|min:3'"
      data-vv-validate-on="blur"
      name="username"
      icon-no-border
      icon="icon icon-user"
      icon-pack="feather"
      placeholder="Usuário"
      v-model="username"
      class="w-full"/>
    <span class="text-danger text-sm">{{ errors.first('username') }}</span>

    <vs-input
      data-vv-validate-on="blur"
      v-validate="'required'"
      type="password"
      name="password"
      icon-no-border
      icon="icon icon-lock"
      icon-pack="feather"
      placeholder="Senha"
      v-model="password"
      class="w-full mt-6"/>
    <span class="text-danger text-sm">{{ errors.first('password') }}</span>

    <div class="flex flex-wrap justify-between my-5 w-full">
      <vs-button :disabled="!validateForm" @click="login"  class="w-full vs-button-dark">Login</vs-button>
    </div>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        username: null,
        password: null,
      }
    },
    computed: {
      validateForm() {
        return !this.errors.any() && this.username != '' && this.password != '';
      },
    },
    methods: {
      login() {

        this.$vs.loading()

        const payload = {
          userDetails: {
            username: this.username,
            password: this.password
          },
          aclChangeRole: this.$acl.change
        }

        this.$store.dispatch('auth/loginJWT', payload)
          .then(() => {
            this.$vs.loading.close()
          })
          .catch(error => {
            this.$vs.loading.close()
            this.$vs.notify({
              title: 'Error',
              text: error.message,
              iconPack: 'feather',
              icon: 'icon-alert-circle',
              color: 'danger'
            })
          })
      }
    }
  }

</script>

