<template>
  <div class="h-screen flex w-full vx-row no-gutter items-center justify-center" id="page-login">
    <div class=" vx-col sm:4 md:w-1/2 lg:w-1/2 xl:w-1/4 sm:m-0 m-4" style="border: #6bbaf9 8px solid; border-radius: 10px;opacity: 0.8">
      <vx-card>
        <div slot="no-body">

          <div class="vx-row no-gutter justify-center items-center">

            <div class="vx-col pt-10 px-10 sm:w-full md:w-full lg:w-full d-theme-dark-bg">
              <img src="@/assets/images/logo/logo_fundo_cinza.png" class="mx-auto logo">
            </div>

          </div>

          <div class="vx-row no-gutter justify-center items-center">

            <div class="vx-col sm:w-full md:w-full lg:w-full d-theme-dark-bg">

              <div class="px-10 pt-10 ">

                <login-jwt></login-jwt>

              </div>
            </div>
          </div>

          <div class="vx-row no-gutter justify-center items-end pb-10">

            <div class="vx-col sm:w-full md:w-full lg:w-1/3 d-theme-dark-bg">
              <img src="@/assets/images/logo/novo_rio_pinheiros.png" class="mx-auto bottom-logos">
            </div>
            <div class="vx-col sm:w-full md:w-full lg:w-1/3 d-theme-dark-bg">
              <img src="@/assets/images/logo/sabesp.png" class="mx-auto bottom-logos" >
            </div>
            <div class="vx-col sm:w-full md:w-full lg:w-1/3 d-theme-dark-bg">
              <img src="@/assets/images/logo/governo_sp.png" class="mx-auto bottom-logos">
            </div>


          </div>
        </div>
      </vx-card>
    </div>
  </div>
</template>

<script>
  import LoginJwt from "./LoginJWT.vue"

  export default {
    components: {
      LoginJwt
    }
  }
</script>

<style lang="scss">
  .bottom-logos {
    max-width: 100%;
    width: 70px;
    height: 70px;
    object-fit: contain;
  }

  .logo{
    max-width: 100%;
    width: 80%;
    height: 80%;
    /*object-fit: scale-down;*/
  }

  .h-screen {
    background: url('~@/assets/images/background.jpeg') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  .login-tabs-container {
    min-height: 350px;

    .con-tab {
      padding-bottom: 14px;
    }

    .con-slot-tabs {
      margin-top: 1rem;
    }
  }
</style>
